import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(
    public translate: TranslateService,
    public router: Router,
    public title: Title
  ) {
    this.langChecker();
  }

  ngOnInit() {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl = event?.url || '';
        this.getPageKeyTitleByUrl(currentUrl);

        // Check if gtag is defined before calling it
        if (typeof (<any>window).gtag === 'function') {
          (<any>window).gtag('event', 'page', event.urlAfterRedirects);
          (<any>window).gtag('event', 'pageview', event.urlAfterRedirects);
        } else {
          console.error('gtag is not defined');
        }

        const scrollToTop = window.setInterval(function () {
          const pos = window.scrollY;
          if (pos > 0) {
            window.scrollTo(0, 0);
          } else {
            window.clearInterval(scrollToTop);
          }
        }, 16);
      }
    });

    this.translate.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.getPageKeyTitleByUrl(this.router.url);
    });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private langChecker() {
    const currentLang = localStorage.getItem('userLang') || this.translate.getBrowserLang() || 'en';
    this.translate.addLangs(['es', 'en', 'fr']);
    this.translate.use(currentLang);
  }

  private getPageKeyTitleByUrl(url: string) {
    let pageKeyTitle = 'home.pageTitle';

    switch (true) {
      case /about/.test(url):
        pageKeyTitle = 'aboutUs.pageTitle';
        break;
      case /services/.test(url):
        pageKeyTitle = 'services.pageTitle';
        break;
      case /web-apps/.test(url):
        pageKeyTitle = 'products.pageTitle';
        break;
      case /mobile-apps/.test(url):
        pageKeyTitle = 'mobileAplications.pageTitle';
        break;
      case /testing-factory/.test(url):
        pageKeyTitle = 'testingFactory.pageTitle';
        break;
      case /consulting/.test(url):
        pageKeyTitle = 'consultancies.pageTitle';
        break;
      case /courses/.test(url):
        pageKeyTitle = 'courses.pageTitle';
        break;
      case /products/.test(url):
        pageKeyTitle = 'products.pageTitle';
        break;
      case /university/.test(url):
        pageKeyTitle = 'university.pageTitle';
        break;
      case /customers/.test(url):
        pageKeyTitle = 'ourClients.pageTitle';
        break;
      case /talent/.test(url):
        pageKeyTitle = 'rse.talent.pageTitle';
        break;
      case /creakind/.test(url):
        pageKeyTitle = 'rse.creakind.pageTitle';
        break;
      default:
        break;
    }

    this.setPageTitle(pageKeyTitle);

  }

  private setPageTitle(titleKey: string) {
    this.translate.get(titleKey).subscribe((res: string) => {
      this.title.setTitle(res);
    });
  }
}
